import React from 'react';
import AppRoutes from './routes/routes';

function App() {
    return (
        <div>
            <AppRoutes />
        </div>
    );
}

export default App;