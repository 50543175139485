import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Lazy load components
const Header = lazy(() => import('../module/layouts/include/header'));
const Footer = lazy(() => import('../module/layouts/include/footer'));
const Register = lazy(() => import('../module/auth/Register'));
const Login = lazy(() => import('../module/auth/Login'));
const Home = lazy(() => import('../module/pages/Home/Home'));

// Layout components
const Layout = ({ children }) => (
    <>
        <Suspense fallback={<div>Loading header...</div>}>
            <Header />
        </Suspense>
        <main>{children}</main>
        <Suspense fallback={<div>Loading footer...</div>}>
            <Footer />
        </Suspense>
    </>
);

function AppRoutes() {
    return (
        <Router>
            <Suspense fallback={
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            }>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/" element={<Layout><Home /></Layout>} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default AppRoutes;
